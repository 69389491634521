import React from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { CONVERSATIONS_QUERY } from './queries';
import { START_CLIENT_INQUIRY } from './mutations';
import Loading from './shared/Loading';
import Error from './shared/Error';
import { getCaseIdFromUrl } from './hooks';
import { useToastContext } from './context';
import { TOASTS } from './shared/dictionary';

const ClientInquiryPanel = () => {
    let caseId = getCaseIdFromUrl();
    const { showToast } = useToastContext();

    const { data, loading, error } = useQuery(CASE_QUERY, {
        variables: {
            id: caseId,
        }
    });

    const [startClientInquiry, { loading: updating }] = useMutation(START_CLIENT_INQUIRY, {
        onCompleted: () => handleSuccess(),
        onError: (error) => handleError(error),
        update(cache, { data: { startClientInquiry: { agentLogs } } }) {
            const query = {
                query: CONVERSATIONS_QUERY,
                variables: {
                    caseId,
                },
            }
            const data = cache.readQuery(query);
            if (data) {
                cache.writeQuery({
                    ...query,
                    data: {
                        agentLogs: [...data.agentLogs, ...agentLogs],
                    }
                });
            }
        },
    });

    const handleToggle = (e) => {
        var variables = {
            id: caseId,
            params: {
                isCiRush: e.target.checked,
            }
        }
        startClientInquiry({ variables });
    }

    const handleSuccess = () => {
        showToast({ ...TOASTS.success, message: 'Client inquiry tags updated.' });

        // Scroll to end of messages
        const container = document.querySelector('#js-messagesContainer');
        if (container) {
            container.scrollTop = container.scrollHeight;
        }

        // Show alert to reload page
        const reloadPageAlert = document.querySelector('#js-reloadPageAlert');
        if (reloadPageAlert) {
            reloadPageAlert.classList.remove('d-none');
        }
    }

    const handleError = (error) => {
        showToast({ ...TOASTS.error, message: error.message });
    }

    if (loading) return <Loading />;
    if (error) return <Error error={error} />;

    const { status, clientInquiryStatus, isCiGeneral, isCiRush } = data.case;

    return (
        <div className="mt-4">
            <div className="fw-bold small mb-3">
                Report Status
            </div>
            <div className="row align-items-center mb-4">
                <div className="col-auto small">Switch report status to</div>
                <div className="col-6">
                    <ClientInquiryDropdown
                        status={status}
                        clientInquiryStatus={clientInquiryStatus}
                        isCiGeneral={isCiGeneral}
                    />
                </div>
            </div>
            <div className="fw-bold small mb-3">
                Report Tags
            </div>
            <div className="form-check form-switch d-flex align-items-center gap-2">
                <input
                    className="form-check-input"
                    type="checkbox"
                    id="ciRushSwitch"
                    style={{ width: '2.25rem', height: '1.125rem' }}
                    defaultChecked={isCiRush}
                    onChange={handleToggle}
                    disabled={updating}
                />
                <label className="form-check-label small mt-0" htmlFor="ciRushSwitch">
                    Tag report as{' '}
                    <span className="fw-bold text-danger">CI-RUSH</span>
                </label>
            </div>
        </div>
    );
}

const ClientInquiryDropdown = ({ status, clientInquiryStatus, isCiGeneral }) => {
    const readyToResume = status === 'ready_to_resume';
    const currentOption = isCiGeneral ? 'general' : readyToResume ? status : clientInquiryStatus;

    return (
        <div className="dropdown dropup d-grid">
            <button
                className="btn btn-sm border rounded d-flex align-items-center justify-content-between dropdown-toggle"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
            >
                <OptionLabel option={currentOption} />
            </button>
            <div className="dropdown-menu w-100">
                <div className="d-flex flex-column">
                    {Object.keys(CLIENT_INQUIRY_OPTIONS).map(key => (
                        <ClientInquiryOption
                            key={key}
                            option={key}
                            currentOption={currentOption}
                            isCiGeneral={isCiGeneral}
                            readyToResume={readyToResume}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
}

const ClientInquiryOption = ({ option, currentOption, isCiGeneral, readyToResume }) => {
    const { step, separator } = CLIENT_INQUIRY_OPTIONS[option] || {};
    const nextStep = isCiGeneral && readyToResume ? [0, 1] : CLIENT_INQUIRY_OPTIONS[currentOption]?.nextStep || [-1, 1];

    return (
        <>
            <button
                className="btn btn-sm hoverable"
                type="button"
                data-bs-toggle="modal"
                data-bs-target={`#bs-clientInquiryModal-${option}`}
                disabled={!nextStep.includes(step)}
            >
                <OptionLabel option={option} />
            </button>
            {separator && <div className="border-bottom my-2" />}
        </>
    );
}

const OptionLabel = ({ option }) => {
    const { label, color } = CLIENT_INQUIRY_OPTIONS[option] || {};

    if (!label) {
        return <span className="opacity-75">Select report status</span>;
    }

    return (
        <div className="d-flex align-items-center gap-2">
            {!!color && (
                <div
                    className={`bg-${color} rounded-pill`}
                    style={{ width: '12px', height: '12px' }}
                />
            )}
            <span>{label}</span>
        </div>
    );
}

const CASE_QUERY = gql`
    query Case($id: ID!) {
        case(id: $id) {
            id
            status
            clientInquiryStatus
            isCiGeneral
            isCiRush
        }
    }
`;

const CLIENT_INQUIRY_OPTIONS = {
    general: {
        label: 'Client Inquiry - Others',
        color: 'dark-red',
        step: -1,
        nextStep: [1],
        separator: true,
    },
    data_confirmation: {
        label: 'Data Confirmation',
        color: 'info',
        step: 1,
        nextStep: [-1, 2, 3],
    },
    request_for_extension: {
        label: 'Request for Extension',
        color: 'info',
        step: 1,
        nextStep: [-1, 2, 3],
    },
    client_request: {
        label: 'Client Request',
        color: 'info',
        step: 1,
        nextStep: [-1, 2, 3],
        separator: true,
    },
    awaiting_response: {
        label: 'Awaiting Response',
        color: 'warning',
        step: 2,
        nextStep: [-1, 1, 3],
    },
    ready_to_resume: {
        label: 'Ready to resume',
        color: 'warning',
        step: 3,
        nextStep: [-1, 0, 1],
        separator: true,
    },
    assigned: {
        label: 'Return to agent',
        step: 0,
        nextStep: [-1, 1],
    },
    submitted: {
        label: 'Return to QC',
        step: 0,
        nextStep: [-1, 1],
    },
}

export default ClientInquiryPanel;