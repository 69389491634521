import { gql, useLazyQuery } from '@apollo/client';
import { ToastContext } from '../components/Webform/context';
import { useCallback, useContext } from 'react';
import $ from 'jquery';
import { debounce } from 'lodash';
import { getCaseIdFromUrl } from '../components/Webform/hooks';

const CHECK_PERMISSION_QUERY = gql`
    query CheckPermission($caseId: ID!, $policy: String!) {
        checkPermission(caseId: $caseId, policy: $policy) {
            permitted
            error
        }
    }
`;

const usePermissionChecker = (args = {
    permitted: () => {},
    restricted: () => {},
    policy: null,
    showToastOnRestrict: true,
    actionsDisabledByDefault: false,
    debounceDelay: 500,
}) => {
    const caseID = getCaseIdFromUrl();
    const { showToast } = useContext(ToastContext);
    const showToastOnRestrict =
        [true, false].includes(args.showToastOnRestrict) ? args.showToastOnRestrict : true;
    const disabledClass = [
        [true, false].includes(args.actionsDisabledByDefault)
                            ? args.actionsDisabledByDefault ? "disabled webform-restricted" : ""
                            : "",
        `webform-restrictable-action-${args.policy}`,
    ].join(" ");

    function onCompleted(data) {
        if(data.checkPermission.permitted) {
            if (args.permitted) args.permitted();
            $(`.webform-restrictable-action-${args.policy}`)
                .removeClass("disabled")
                .removeClass("webform-restricted");
        } else {
            if (showToastOnRestrict) {
                showToast({ title: "Action not allowed!", variant: "error", message: data.checkPermission.error });
            }
            if (args.restricted) args.restricted();
            $(`.webform-restrictable-action-${args.policy}`)
                .addClass("disabled")
                .addClass("webform-restricted");
        }

        $(".webform-restrictable-action.skip-disable-toggle")
            .removeClass("disabled")
            .removeClass("webform-restricted");
    }
    const [checkPermission] = useLazyQuery(
        CHECK_PERMISSION_QUERY,
        {
            fetchPolicy: 'no-cache', // important, query will only execute once if cached
            variables: { caseId: caseID, policy: args.policy },
            onCompleted: onCompleted
        }
    );

    const debouncedCheckPermission = useCallback(debounce(checkPermission, args.debounceDelay || 500), []);
    
    return {
        checkPermission,
        debouncedCheckPermission,
        disabledClass
    };
};

export default usePermissionChecker;