import React, { useState } from 'react';
import { Modal as BootstrapModal  } from 'bootstrap';
import { useMutation } from '@apollo/client';
import { CONVERSATIONS_QUERY } from './queries';
import { START_CLIENT_INQUIRY } from './mutations';
import { useToastContext } from './context';
import { getCaseIdFromUrl } from './hooks';
import { TOASTS } from './shared/dictionary';

const ClientInquiryModals = () => {

    return (
        CLIENT_INQUIRY_MODALS.map(props => (
            <ClientInquiryModal key={props.id} {...props} />
        ))
    );
}

const ClientInquiryModal = ({ title, body, notification, id }) => {

    return (
        <div
            className="modal fade"
            id={`bs-clientInquiryModal-${id}`}
            tabIndex="-1"
            aria-labelledby={`bs-clientInquiryModal-${id}`}
            aria-hidden="true"
        >
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title">{title}</h4>
                    </div>
                    <div className="modal-body">
                        <p>{body}</p>
                        <ClientInquiryForm
                            id={id}
                            notification={notification}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

const ClientInquiryForm = ({ id, notification }) => {
    let caseId = getCaseIdFromUrl();
    const { showToast } = useToastContext();
    const [message, setMessage] = useState('');

    const [startClientInquiry, { loading }] = useMutation(START_CLIENT_INQUIRY, {
        onCompleted: () => handleSuccess(),
        onError: (error) => handleError(error),
        update(cache, { data: { startClientInquiry: { caseChat, agentLogs } } }) {
            const query = {
                query: CONVERSATIONS_QUERY,
                variables: {
                    caseId,
                },
            }
            const data = cache.readQuery(query);
            if (data) {
                cache.writeQuery({
                    ...query,
                    data: {
                        caseChats: !!caseChat ? [...data.caseChats, caseChat] : data.caseChats,
                        agentLogs: [...data.agentLogs, ...agentLogs],
                    }
                });
            }
        },
        refetchQueries: ['WorkSessions'],
    });

    const handleSubmit = (e) => {
        e.preventDefault();

        var variables = {
            id: caseId,
            params: {
                clientInquiryStatus: id,
            }
        }
        startClientInquiry({ variables });
    }

    const handleClose = () => {
        var modal = BootstrapModal.getInstance(document.getElementById(`bs-clientInquiryModal-${id}`));
        modal.hide();
    }

    const handleSuccess = () => {
        showToast({ ...TOASTS.success, message: notification });
        setMessage('');
        handleClose();

        // Scroll to end of messages
        const container = document.querySelector('#js-messagesContainer');
        if (container) {
            container.scrollTop = container.scrollHeight;
        }

        // Show alert to reload page
        const reloadPageAlert = document.querySelector('#js-reloadPageAlert');
        if (reloadPageAlert) {
            reloadPageAlert.classList.remove('d-none');
        }
    }

    const handleError = (error) => {
        showToast({ ...TOASTS.error, message: error.message });
    }

    return (
        <form onSubmit={handleSubmit}>
            {/* <div className="mb-4">
                <label className="form-label small">Your message</label>
                <textarea
                    className="form-control"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                />
            </div> */}
            <div className="d-flex justify-content-end gap-4">
                <button
                    type="button"
                    className="btn btn-link-default btn-sm"
                    data-bs-dismiss="modal"
                >
                    Cancel
                </button>
                <button
                    type="submit"
                    className="btn btn-dark btn-sm"
                    disabled={loading}
                >
                    Confirm
                </button>
            </div>
        </form>
    );
}

const CLIENT_INQUIRY_MODALS = [
    {
        id: 'data_confirmation',
        title: 'Request for data confirmation',
        body: 'You are about to pause encoding and start a client inquiry. Click Confirm to proceed.',
        notification: 'Client inquiry started.',
    },
    {
        id: 'request_for_extension',
        title: 'Request for extension',
        body: 'You are about to pause encoding and start a client inquiry. Click Confirm to proceed.',
        notification: 'Client inquiry started.',
    },
    {
        id: 'client_request',
        title: 'Client request',
        body: 'You are about to restart encoding due to a client request. Click Confirm to proceed.',
        notification: 'Client request started.',
    },
    {
        id: 'awaiting_response',
        title: 'Awaiting response',
        body: 'Are you sure you want to change client inquiry status to awaiting response?',
        notification: 'Awaiting response from client.',
    },
    {
        id: 'ready_to_resume',
        title: 'Ready to resume',
        body: 'Are you sure you want to end client inquiry?',
        notification: 'Client inquiry ended.',
    },
    {
        id: 'assigned',
        title: 'Return to agent',
        body: 'Are you sure you want to end client inquiry and resume encoding to agent?',
        notification: 'Agent can now resume encoding.',
    },
    {
        id: 'submitted',
        title: 'Return to QC?',
        body: 'Are you sure you want to end client inquiry and resume proofing to QC?',
        notification: 'QC can now resume proofing.',
    },
    {
        id: 'general',
        title: 'Client Inquiry - Others',
        body: 'Are you sure you want to change client inquiry status to CI-Others?',
        notification: 'Client inquiry status updated.',
    }
];

export default ClientInquiryModals;