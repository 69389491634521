import React, { useRef } from 'react';
import { STATUSES } from './shared/dictionary';
import usePermissionChecker from '../../hooks/usePermissionChecker';
import { Offcanvas } from 'bootstrap';

const MessageFilters = ({ filters, setFilters }) => {
    const filtersCount = Object.values(filters).filter(value => !!value).length;
    const filterRef = useRef(filters);
    const { checkPermission } = usePermissionChecker({
        policy: "webform_access",
        restricted: () => {
            let offcanvas = Offcanvas.getOrCreateInstance(document.getElementById("webformMessages"));
            offcanvas?.hide();
        },
        permitted: () => {
            setFilters(filterRef.current);
        }
    });

    const applyFilter = (filter) => {
        filterRef.current = { ...filters, ...filter };
        checkPermission();
    }

    const clearFilter = () => {
        filterRef.current = {};
        checkPermission();
    }
    return (
        <div className="d-flex align-items-center justify-content-between gap-2">
            <div className="dropdown">
                <button
                    className="btn btn-outline-dark btn-sm rounded-pill"
                    type="button"
                    data-bs-toggle="dropdown"
                    data-bs-auto-close="outside"
                    aria-expanded="false"
                >
                    <div className="d-flex align-items-baseline gap-2">
                        <i className="fe fe-sliders" />
                        <span>Filters</span>
                        {!!filtersCount && (
                            <span className="badge bg-primary rounded-pill">
                                {filtersCount}
                            </span>
                        )}
                    </div>
                </button>
                <FiltersDropdown
                    filters={filters}
                    applyFilter={applyFilter}
                />
            </div>
            {!!filtersCount && (
                <button
                    type="button"
                    className="btn btn-sm p-0 text-primary fw-bold"
                    onClick={clearFilter}
                >
                    Clear filters
                </button>
            )}
        </div>
    );
}

const FiltersDropdown = ({ filters, applyFilter }) => {

    return (
        <div className="dropdown-menu p-3 shadow" style={{ width: '240px' }}>
            <div className="row">
                <label className="col-3 col-form-label fs-5">Status</label>
                <div className="col-9">
                    <select
                        className="form-select fs-5"
                        onChange={(e) => applyFilter({ status: e.target.value })}
                        value={filters.status || ''}
                    >
                        <option value="">All statuses</option>
                        {Object.keys(STATUSES).map(key => (
                            <option key={key} value={key}>
                                {STATUSES[key].label}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
            <div className="row mt-3">
                <label className="col-3 col-form-label fs-5">Type</label>
                <div className="col-9">
                    <select
                        className="form-select fs-5"
                        onChange={(e) => applyFilter({ messageType: e.target.value })}
                        value={filters.messageType || ''}
                    >
                        <option value="">All messages</option>
                        <option value="chats">Chats</option>
                        <option value="logs">Logs</option>
                    </select>
                </div>
            </div>
        </div>
    );
}

export default MessageFilters;