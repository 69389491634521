export const TOASTS = {
    success: {
        title: 'Success!',
        message: 'Your changes have been applied.',
        variant: 'success',
    },
    error: {
        title: 'An error occurred',
        message: 'Something went wrong. Please contact an administrator.',
        variant: 'error',
    }
}

export const ACCESS_TYPES = {
    write: 'can edit',
    read: 'can view',
    comment: 'can comment',
};

export const REPORT_SCORES = {
    1: 'No accounts found.',
    2: 'Accounts found; no highlights.',
    3: 'Accounts found; possible highlights found.',
    4: 'Accounts found; concrete highlights found.',
}

export const STATUSES = {
    open: {
        label: 'Open',
        color: 'secondary'
    },
    assigned: {
        label: 'Assigned',
        color: 'primary',
        actionable: true,
    },
    in_progress: {
        label: 'In Progress',
        color: 'primary',
        actionable: true,
    },
    submitted: {
        label: 'Ready for QC',
        color: 'info',
        actionable: true,
    },
    in_qc: {
        label: 'QC in Progress',
        color: 'warning',
        actionable: true,
    },
    completed: {
        label: 'Completed',
        color: 'success'
    },
    fail: {
        label: 'Failed',
        color: 'danger-soft',
        actionable: true,
    },
    client_inquiries: {
        label: 'Client Inquiry',
        color: 'danger-soft'
    },
    ready_to_resume: {
        label: 'Ready to Resume',
        color: 'primary',
    },
    cancelled: {
        label: 'Cancelled',
        color: 'danger-soft'
    },
}

export const CLIENT_INQUIRY_STATUSES = {
    data_confirmation: {
        label: 'Data Confirmation',
        color: 'info',
    },
    request_for_extension: {
        label: 'Request for Extension',
        color: 'info',
    },
    client_request: {
        label: 'Client Request',
        color: 'info',
    },
    awaiting_response: {
        label: 'Awaiting Response',
        color: 'warning',
    },
};

export const VERDICTS = {
    pass: {
        label: 'QC Passed',
        color: 'success',
        icon: 'check-circle',
        indicator: 'P',
    },
    fail: {
        label: 'QC Failed',
        color: 'danger',
        icon: 'alert-circle',
        indicator: 'F',
    },
    resolved: {
        label: 'No final assessment',
        color: 'secondary',
        icon: 'help-circle',
        indicator: '?',
    },
    default: {
        label: 'No assessment',
        color: 'secondary',
        icon: 'minus-circle',
        indicator: '-',
    },
}

export const MATCH_TYPES = {
    'present': 'Information found about individual from end-user\'s pre-selected categories.',
    'may-be': 'Internet presence detected. No information pertaining to end-user\'s pre-selected categories was found.',
    'not-present': 'No internet presence detected.',
}

export const ALLOWED_IMAGE_TYPES = [
    'image/jpeg',
    'image/jpg',
    'image/png'
];
