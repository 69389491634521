import React, { useEffect, useRef, useState } from 'react';
import { ToastContext } from './Webform/context';
import { Toast } from 'bootstrap';

function Toastbox({ children }) {
    const ref = useRef(null);
    const instance = useRef(null);
    const [body, setBody] = useState('');
    const [title, setTitle] = useState('');
    const [variant, setVariant] = useState('success');
    const [placement, setPlacement] = useState('top-0 end-0');

    function showToast({ title: newTitle, message: newMessage, variant: newVariant, placement: newPlacement }) {
        // clear all toasts created from rails to avoid said toasts from unnessesarily showing up
        const existingToasts = document.querySelectorAll('.js-rails-toasts');
        if (Boolean(existingToasts.length)) {
            existingToasts.forEach(function(el) {
                el.remove();
            });
        }

        let placementClass = (newPlacement || "top-right").split("-")
                                .map(loc => {
                                    if (loc === "bottom") return "bottom-0";
                                    if (loc === "top") return "top-0";
                                    if (loc === "left") return "start-0";
                                    if (loc === "right") return "end-0";
                                    return null;
                                }).join(" ");

        setTitle(newTitle);
        setBody(newMessage);
        setVariant(newVariant);
        setPlacement(placementClass);
        instance.current?.show();

        const toasts = document.querySelectorAll('.toast');
        if (Boolean(toasts.length)) {
            toasts.forEach(function(el) {
                new window.Toast(el).show();
            });
        }
    }

    function hideToast() {
        setTitle('');
        setBody('');
        setVariant('success');
        instance.current?.hide();
    }

    useEffect(() => {
        const el = Toast.getOrCreateInstance(ref?.current);
        instance.current = el;
    }, [body]);

    return (
        <ToastContext.Provider value={{ showToast, hideToast }}>
            {children}
            <div className={`toast-container position-fixed ${placement} p-3 zindex-tooltip`}>
                <div
                    className="toast border-0"
                    role="alert"
                    aria-live="assertive"
                    aria-atomic="true"
                    data-bs-autohide="true"
                    ref={ref}
                >
                    <div className={`toast-header border-bottom-0 text-bg-${variant}`}>
                        <strong className="me-auto">{title}</strong>
                        <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="toast"
                            aria-label="Close"
                            onClick={hideToast}
                        />
                    </div>
                    <div className={`toast-body pt-0 pb-3 rounded-bottom text-bg-${variant}`}>
                        {body}
                    </div>
                </div>
            </div>
        </ToastContext.Provider>
    );
}

export default Toastbox;
