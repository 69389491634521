import { gql } from '@apollo/client';
import {
    FEEDBACK_FIELDS,
    CASE_DETAILS,
    PROFILE_INFORMATION,
    REPORT_SUMMARIES,
    CASE_NETWORK_FIELDS,
    CASE_FIELDS,
    CASE_STATUS_FIELDS,
    CASE_NETWORK_INDEX_FIELDS,
    ATTACHMENT_FIELDS,
    WORK_SESSION_FIELDS,
    CASE_CHAT_FIELDS,
    AGENT_LOG_FIELDS,
    USER_PROFILE_FIELDS,
    CONTRIBUTOR_FIELDS
} from './fragments';

export const CREATE_FEEDBACK = gql`
    ${FEEDBACK_FIELDS}
    mutation CreateFeedback($params: FeedbackInput!) {
        createFeedback(input: { params: $params }) {
            feedback {
                ...FeedbackFields
                case {
                    id
                    caseNotes
                }
            }
        }
    }
`;

export const UPDATE_FEEDBACK = gql`
    ${FEEDBACK_FIELDS}
    mutation UpdateFeedback($id: ID!, $params: FeedbackInput!) {
        updateFeedback(input: { id: $id, params: $params }) {
            feedback {
                ...FeedbackFields
                case {
                    id
                    caseNotes
                }
            }
        }
    }
`;

export const UPDATE_CASE = gql`
    ${ATTACHMENT_FIELDS}
    mutation UpdateCase($id: ID!, $params: CaseInput!) {
        updateCase(input: { id: $id, params: $params }) {
            case {
                id
                profilePhoto {
                    ...AttachmentFields
                }
                mapViewPhoto {
                    ...AttachmentFields
                }
                streetViewPhoto {
                    ...AttachmentFields
                }
            }
        }
    }
`;

export const UPDATE_CASE_OTHER_DATA = gql`
    mutation UpdateCase(
        $id: ID!
        $params: CaseInput!
    ) {
        updateCase(input: {
            id: $id
            params: $params
        }) {
            case {
                id
                supportLinks
                usernames
                caseSearchStrings {
                    networkId
                    networkName
                    searchStrings
                }
            }
        }
    }
`;

export const UPDATE_RELATIVE = gql`
    ${ATTACHMENT_FIELDS}
    mutation UpdateRelative($id: ID!, $params: RelativeInput!) {
        updateRelative(input: { id: $id, params: $params }) {
            relative {
                id
                photo {
                    ...AttachmentFields
                }
            }
        }
    }
`;

export const UPDATE_FINDINGS_DOCUMENT = gql`
    mutation UpdateFindingsDocument($id: ID!, $params: FindingsDocumentInput!) {
        updateFindingsDocument(input: { id: $id, params: $params }) {
            findingsDocument {
                id
                document {
                    filename
                    url
                }
            }
        }
    }
`;

export const UPDATE_SCREENSHOT = gql`
    mutation UpdateScreenshot($id: ID!, $params: ScreenshotInput!) {
        updateScreenshot(input: { id: $id, params: $params }) {
            screenshot {
                id
                isPinned
                imageFile {
                    filename
                    url
                    fileSize
                    verticalVariantUrl
                    basename
                }
                metadata {
                    filename
                    url
                }
                videoFiles {
                    filename
                    url
                    signedId
                }
            }
        }
    }
`;

export const UPDATE_CASE_DETAILS = gql`
    ${CASE_DETAILS}
    mutation UpdateCaseDetails(
        $id: ID!,
        $params: CaseDetailsInput!,
    ) {
        updateCaseDetails(input: {
            id: $id,
            params: $params,
        }) {
            case {
                ...CaseDetails
                caseSearchStrings {
                    networkId
                    networkName
                    searchStrings
                }
            }
        }
    }
`;

export const UPDATE_PROFILE_INFORMATION = gql`
    ${PROFILE_INFORMATION}
    ${FEEDBACK_FIELDS}
    mutation UpdateProfileInformation(
        $id: ID!,
        $params: CaseInput!,
        $relatives: [RelativeInput!]!,
        $feedbacks: [FeedbackInput!]!,
    ) {
        updateProfileInformation(input: {
            id: $id,
            params: $params,
            relatives: $relatives,
            feedbacks: $feedbacks,
        }) {
            case {
                ...ProfileInformation
            }
            feedbacks {
                ...FeedbackFields
            }
        }
    }
`;

export const UPDATE_REPORT_SUMMARIES = gql`
    ${REPORT_SUMMARIES}
    ${FEEDBACK_FIELDS}
    mutation UpdateReportSummaries(
        $id: ID!,
        $params: CaseInput!,
        $findingsDocuments: [FindingsDocumentInput!]!,
        $screenshots: [ScreenshotInput!]!,
        $feedbacks: [FeedbackInput!]!,
    ) {
        updateReportSummaries(input: {
            id: $id,
            params: $params,
            findingsDocuments: $findingsDocuments,
            screenshots: $screenshots,
            feedbacks: $feedbacks,
        }) {
            case {
                ...ReportSummaries
            }
            feedbacks {
                ...FeedbackFields
            }
        }
    }
`;

export const UPDATE_CASE_NETWORK = gql`
    ${CASE_NETWORK_FIELDS}
    ${FEEDBACK_FIELDS}
    mutation UpdateCaseNetwork(
        $id: ID!,
        $params: CaseNetworkInput!,
        $findingsDocuments: [FindingsDocumentInput!],
        $screenshots: [ScreenshotInput!],
        $feedbacks: [FeedbackInput!],
    ) {
        updateCaseNetwork(input: {
            id: $id,
            params: $params,
            findingsDocuments: $findingsDocuments,
            screenshots: $screenshots,
            feedbacks: $feedbacks,
        }) {
            caseNetwork {
                ...CaseNetworkFields
            }
            feedbacks {
                ...FeedbackFields
            }
        }
    }
`;

export const CREATE_CASE_NETWORKS_BY_CATEGORY = gql`
    ${CASE_NETWORK_INDEX_FIELDS}
    mutation CreateCaseNetworksByCategory(
        $caseId: ID!
        $categoryId: ID!
    ) {
        createCaseNetworksByCategory(input: {
            caseId: $caseId
            categoryId: $categoryId
        }) {
            case {
                id
                caseNetworks {
                    ...CaseNetworkIndexFields
                }
                categories {
                    id
                    name
                }
            }
        }
    }
`;

export const UPDATE_CASE_NETWORK_SCREENSHOTS_ORDINALITY = gql`
    ${CASE_NETWORK_FIELDS}
    mutation updateCaseNetworkScreenshotsOrdinality(
        $id: ID!
        $screenshots: [ScreenshotInput!]
    ) {
        result: updateCaseNetworkScreenshotsOrdinality(
            input: { id: $id, screenshots: $screenshots }
        ) {
            success
            errors
            caseNetwork {
                ...CaseNetworkFields
            }
        }
    }
`;

export const UPDATE_CASE_HIGHLIGHT_SCREENSHOTS_ORDINALITY = gql`
    ${CASE_FIELDS}
    mutation updateCaseHighlightScreenshotsOrdinality(
        $id: ID!
        $screenshots: [ScreenshotInput!]
    ) {
        result: updateCaseHighlightScreenshotsOrdinality(
            input: { id: $id, screenshots: $screenshots }
        ) {
            success
            errors
            case {
                ...CaseFields
                reportHighlightsScreenshots {
                    id
                    position
                }
            }
        }
    }
`;

export const TOGGLE_CASE_BUILT_IN_NEXT = gql`
    ${CASE_STATUS_FIELDS}
    mutation ToggleCaseBuiltInNext($id: ID!) {
        toggleCaseBuiltInNext(input: {
            id: $id,
        }) {
            case {
                ...CaseStatusFields
            }
            success
            errors
        }
    }
`;

export const CASE_START_REPORT = gql`
    ${CASE_STATUS_FIELDS}
    mutation CaseStartReport($id: ID!) {
        result: caseStartReport(input: {
            id: $id,
        }) {
            success
            errors
            case {
                ...CaseStatusFields
            }
        }
    }
`;

export const CASE_START_QC = gql`
    ${CASE_STATUS_FIELDS}
    mutation CaseStartQc($id: ID!) {
        result: caseStartQc(input: {
            id: $id,
        }) {
            success
            errors
            case {
                ...CaseStatusFields
            }
        }
    }
`;

export const CREATE_CASE_NETWORK = gql`
    ${CASE_NETWORK_FIELDS}
    mutation createCaseNetwork($caseId: ID!, $networkId: ID!) {
        result: createCaseNetwork(input: {caseId: $caseId, networkId: $networkId}) {
          success
          errors
          caseNetwork {
            ...CaseNetworkFields
          }
        }
      }
`;

export const UPDATE_CASE_NETWORKS_ORDINALITY = gql`
    ${CASE_FIELDS}
    mutation updateCaseNetworksOrdinality($id: ID!, $caseNetworks: [CaseNetworkInput!]) {
        result: updateCaseNetworksOrdinality(
            input: {id: $id, caseNetworks: $caseNetworks}
        ) {
            success
            errors
            case {
                ...CaseFields
            }
        }
    }
`;

export const DELETE_CASE_NETWORK = gql`
    mutation deleteCaseNetwork($id: ID!) {
        result: deleteCaseNetwork(input: {id: $id}) {
            success
            errors
        }
    }
`;

export const RETRACT_CASE_APPROVAL = gql`
    ${CASE_STATUS_FIELDS}
    mutation retractCaseApproval(
        $caseId: ID!
        $caseApprovalId: ID!
    ) {
        retractCaseApproval(input: {
            caseId: $caseId
            caseApprovalId: $caseApprovalId
        }) {
            caseApproval {
                id
                isRetracted
                case {
                    ...CaseStatusFields
                }
            }
            errors
            success
        }
    }
`;

export const UPDATE_CASE_SCORE = gql`
    mutation UpdateCase(
        $id: ID!
        $params: CaseInput!
    ) {
        updateCase(input: {
            id: $id
            params: $params
        }) {
            case {
                id
                score
                scoreRemarks
            }
        }
    }
`;

export const BULK_CREATE_FEEDBACK = gql`
    ${FEEDBACK_FIELDS}
    mutation BulkCreateFeedback(
        $id: ID!
        $feedbacks: [FeedbackInput!]!
    ) {
        bulkCreateFeedback(input: {
            id: $id
            feedbacks: $feedbacks
        }) {
            feedbacks {
                ...FeedbackFields
            }
            case {
                id
                caseNotes
            }
        }
    }
`;

export const START_WORK_SESSION = gql`
    ${WORK_SESSION_FIELDS}
    mutation StartWorkSession(
        $params: WorkSessionInput!
    ) {
        startWorkSession(input: {
            params: $params
        }) {
            workSession {
                ...WorkSessionFields
            }
        }
    }
`;

export const END_WORK_SESSION = gql`
    ${WORK_SESSION_FIELDS}
    mutation EndWorkSession(
        $params: WorkSessionInput!
    ) {
        endWorkSession(input: {
            params: $params
        }) {
            workSession {
                ...WorkSessionFields
            }
        }
    }
`;

export const CREATE_CASE_CHAT = gql`
    ${CASE_CHAT_FIELDS}
    mutation CreateCaseChat(
        $params: CaseChatInput!
    ) {
        createCaseChat(input: {
            params: $params
        }) {
            caseChat {
                ...CaseChatFields
            }
        }
    }
`;

export const UPDATE_CASE_CHAT = gql`
    ${CASE_CHAT_FIELDS}
    mutation UpdateCaseChat(
        $id: ID!
        $params: CaseChatInput!
    ) {
        updateCaseChat(input: {
            id: $id
            params: $params
        }) {
            caseChat {
                ...CaseChatFields
            }
        }
    }
`;

export const START_CLIENT_INQUIRY = gql`
    ${CASE_STATUS_FIELDS}
    ${AGENT_LOG_FIELDS}
    mutation StartClientInquiry(
        $id: ID!
        $params: CaseClientInquiryInput!
    ) {
        startClientInquiry(input: {
            id: $id
            params: $params
        }) {
            case {
                ...CaseStatusFields
                isCiGeneral
                isCiRush
            }
            agentLogs {
                ...AgentLogFields
            }
        }
    }
`;

export const ASSIGN_CASE = gql`
    ${USER_PROFILE_FIELDS}
    ${CONTRIBUTOR_FIELDS}
    mutation AssignCase(
        $caseId: ID!
        $userId: ID!
        $role: String!
    ) {
        assignCase(input: {
            caseId: $caseId
            userId: $userId
            role: $role
        }) {
            case {
                id
                agent {
                    ...UserProfile
                }
                assignedQc {
                    ...UserProfile
                }
                assignedAqcm {
                    ...UserProfile
                }
                contributors {
                    ...ContributorFields
                }
            }
        }
    }
`
